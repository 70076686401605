<template>
  <div id="app">
    <HeaderPart />
    <div class="socialMediaBox" v-if="screenWidth > screenBreakLG">
      <a href="https://instagram.com/herbxfilm" target="_blank"
        ><img src="@/assets/icons/instagram.svg" alt="Instagram Icon"
      /></a>
    </div>
    <main role="main">
      <transition name="fade" mode="in-out">
        <router-view :key="$route.fullPath" />
      </transition>
    </main>
    <FooterPart />
    <GoTopBtn />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useWindowScroll } from "@vueuse/core";
export default {
  name: "App",
  metaInfo() {
    // Basisvariablen
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const currentUrl = `${baseUrl}${this.$route.fullPath}`;
    const imageUrl = baseUrl + require("@/assets/images/prodPhoto.jpg");

    // Einheitliche Beschreibungen und Titel
    const siteTitle = "herbX film- und fernsehproduktion GmbH";
    const description =
      "Offizielle Website der herbX / film film- und fernsehproduktion GmbH.";
    const fullTitle = `${siteTitle} - ${this.capitalizedLinkText}`;

    return {
      title: fullTitle, // Dynamischer Titel mit Ergänzung
      meta: [
        // SEO Meta-Tags
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "description", content: description },
        { name: "robots", content: "index, follow" }, // SEO: Anweisung an Suchmaschinen
        { name: "author", content: siteTitle }, // SEO: Seitenautor

        // Open Graph Meta-Tags (Social Media)
        { property: "og:title", content: fullTitle }, // Open Graph Titel
        { property: "og:description", content: description },
        { property: "og:type", content: "website" },
        { property: "og:url", content: currentUrl }, // Open Graph URL
        { property: "og:image", content: imageUrl }, // Open Graph Bild
        { property: "og:site_name", content: siteTitle },
        { property: "og:locale", content: "de" }, // Sprache für Open Graph

        // Twitter Cards Meta-Tags
        { name: "twitter:card", content: "summary_large_image" }, // Twitter Card Type
        { name: "twitter:title", content: fullTitle }, // Twitter Titel
        { name: "twitter:description", content: description },
        { name: "twitter:image", content: imageUrl }, // Twitter Bild
      ],
      link: [
        {
          rel: "canonical",
          href: currentUrl, // Setzt die Canonical-URL mit der Basis-URL und dem aktuellen Pfad
        },
      ],
      htmlAttrs: {
        lang: this.curLang || "de", // Sprache der Seite
      },
    };
  },
  computed: {
    ...mapState([
      "cmsUrl",
      "screenWidth",
      "screenBreakLG",
      "activeMenuPoint",
      "navOpen",
      "token",
      "pages",
    ]),
    capitalizedLinkText() {
      const linkText = this.$route.path.replace("/", "") || ""; // Entfernt den führenden Slash
      return linkText.charAt(0).toUpperCase() + linkText.slice(1); // Erster Buchstabe groß
    },
    page: {
      get() {
        return (
          this.pages.find(
            (page) => page.attributes.pagename === this.$route.name
          )?.attributes || {}
        );
      },
      deep: true,
    },
  },
  components: {
    HeaderPart: () => import("@/components/templateParts/HeaderPart.vue"),
    FooterPart: () => import("@/components/templateParts/FooterPart.vue"),
    GoTopBtn: () => import("@/components/GoTopButton.vue"),
  },

  methods: {
    handleResize() {
      this.$store.dispatch("setScreenWidth");
    },
    async fetchInitalData() {
      // Productions
      let stateName = "productions";
      let apiUrl = `${process.env.VUE_APP_API_URL}/api/${stateName}?populate=*&sort[0]=premiere:desc&pagination[limit]=150`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });

      // Home Productions - Achtung API hbx-home state hbx_home !!!
      stateName = "hbx_home";
      apiUrl = `${process.env.VUE_APP_API_URL}/api/hbx-home?populate=*`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });

      // History
      stateName = "histories";
      apiUrl = `${process.env.VUE_APP_API_URL}/api/${stateName}?populate=*&sort[0]=year:desc&pagination[limit]=150`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });

      // News
      stateName = "articles";
      apiUrl = `${process.env.VUE_APP_API_URL}/api/${stateName}?populate=*&sort[0]=releasedate:desc&pagination[limit]=200`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });

      // faqs
      stateName = "faqs";
      apiUrl = `${process.env.VUE_APP_API_URL}/api/${stateName}?populate=*&sort[0]=question`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });

      // header Images
      stateName = "headerimg";
      apiUrl = `${process.env.VUE_APP_API_URL}/api/${stateName}?populate[newsHeaderImage][populate]=*&populate[newsarchiveHeaderImage][populate]=*&populate[historyHeaderImages][populate]=*&populate[contactHeaderImage][populate]=*`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });

      // privacy
      stateName = "privacy";
      apiUrl = `${process.env.VUE_APP_API_URL}/api/${stateName}?populate=*`;
      await this.$store.dispatch("fetchDataFromApi", {
        apiUrl,
        stateName,
      });
    },
  },
  created() {
    this.fetchInitalData();
    this.$store.dispatch("initializeLanguage");
  },
  mounted() {
    // Set ScreenWidth
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    // Update ScrollPosition
    const { y } = useWindowScroll();
    const handleScroll = () => {
      this.$store.commit("SET_SCROLL_POSITION", y.value);
    };
    window.addEventListener("scroll", handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/main.sass'
.socialMediaBox
  position: fixed
  top: 38vh
  left: 0
  width: 3.5rem
  height: 3rem
  background-color: $gold
  z-index: 5
  transition: transform .5s
  img
    width: 50%
    margin: 15% 25%
  &:hover
    transform: scale(1.1)

  &.mobile
    bottom: 40vh
    right: 0 !important
    left: auto
    top: auto
    img
      width: 2.5rem
      height: 2.5rem
      margin-top: .4rem
      padding: 0
      transform: translateX(-50%)
</style>
